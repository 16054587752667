import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { useContext, useState } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import TournamentDropdown from '../components/common/TournamentDropdown'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'
import PlayerLeaderboard from '../components/charts/PlayerLeaderboard'
import TensionStats from '../components/charts/TensionStats'

const PlayerInsightsDashboard = () => {
  const { tournament } = useContext(SharedDataContext)
  const [selectedTournament, setSelectedTournament] = useState(tournament)
  const [tensionUnits, setTensionUnits] = useState('lb')

  return (
    <Container fluid className='mx-md-0 px-md-0'>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Player Insights</h3>
        </Col>
      </Row>
      <Container>
        <Row className='gy-1 mb-3'>
          <Col xs={12} md={6}>
            <TournamentDropdown value={selectedTournament} handleSelect={(t) => setSelectedTournament(t)} />
          </Col>
          <Col xs='auto' className='align-content-center'>Tension units:</Col>
          <Col xs={6} md={1}>
            <Dropdown
              onSelect={eventKey => setTensionUnits(eventKey)}
              className='w-100'
            >
              <Dropdown.Toggle variant='input' className='w-100 justify-content-between'>
                {tensionUnits}
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                {['lb', 'kg'].map(v => (
                  <Dropdown.Item key={v} eventKey={v}>{v}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>

      <Container className='mb-4'>
        <TensionStats event={selectedTournament} tensionUnits={tensionUnits} />
      </Container>
      <Container>
        <Row className='gx-0 gy-2 pb-2 bg-white rounded justify-content-center'>
          <Col xs={12} md={8}>
            <PlayerLeaderboard event={selectedTournament}/>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default withTournamentSelectionRequired(PlayerInsightsDashboard)
