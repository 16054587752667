import { find } from 'lodash'
import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

const propTypes = {
  value: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired
}

const TournamentDropdown = ({ value, handleSelect }) => {
  const [tournaments, setTournaments] = useState([])
  const { apiGetTournaments } = useDataService()

  useEffect(() => {
    apiGetTournaments({ approved: true }, 1, 100).then(res => setTournaments(res.tournaments))
  }, [])

  useEffect(() => {
    if (tournaments.length > 0 && value && value._id && !value.name) {
      handleSelect(find(tournaments, t => t._id === value._id))
    }
  }, [tournaments])

  const handleTournamentSelection = (eventKey) => {
    const t = find(tournaments, t => t._id === eventKey)
    handleSelect(t)
  }

  const isActive = (openDate, closeDate) => {
    const now = DateTime.now()
    return now > DateTime.fromISO(openDate) && now < DateTime.fromISO(closeDate)
  }

  return (
    <Dropdown
      onSelect={handleTournamentSelection}
      className='w-100'
    >
      <Dropdown.Toggle variant='input' className='w-100 justify-content-between'>
        {value ? value.name ?? find(tournaments, t => t._id === value._id)?.name : 'Select tournament' }
      </Dropdown.Toggle>

      <Dropdown.Menu className='w-100 bg-white shadow-sm' style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
        {tournaments.map(t => (
          <Dropdown.Item key={t._id} eventKey={t._id}>{t.name} <span className={`ms-1 text-small ${isActive(t.openDate, t.closedDate) ? 'text-success' : 'text-secondary'}`}>&#x2022; {isActive(t.openDate, t.closedDate) ? 'active' : 'past'}</span></Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

TournamentDropdown.propTypes = propTypes

export default TournamentDropdown
