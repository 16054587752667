import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { object } from 'prop-types'
import { ListGroup } from 'react-bootstrap'
import Loader from './Loader'

const propTypes = {
  event: object.isRequired
}

const PlayerLeaderboard = ({ event }) => {
  const { apiGetMetrics } = useDataService()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    apiGetMetrics(event._id, 'player-leaderboard').then(res => {
      setData(res)
      setLoading(false)
    })
  }, [event])

  if (loading) {
    return <Loader />
  } else if (data?.length === 0) {
    return (
    <div className='text-center'>
      <h5>Player Leaderboard</h5>
      No data to display
    </div>
    )
  }

  return (
    <div className='px-3'>
      <h5 className='text-center mb-2'>Player Leaderboard</h5>
      <div className='d-flex justify-content-start mx-2 text-body fw-bold mb-1'>
        <span>#</span>
        <span className='ms-1'>Player</span>
        <div className='ms-auto d-flex flex-basis-30 justify-content-end'>
          <span className='mx-auto'>Daily Average</span>
          <span>Total Racquets</span>
        </div>
      </div>
      <ListGroup as='ol' numbered>
          {data.map((r, i) => (
            <ListGroup.Item as='li' key={i} className='d-flex justify-content-start'>
              <span className='ms-1 me-auto'>{r.customer}</span>
              <div className='ms-auto d-flex flex-basis-30 justify-content-between'>
                <span className='mx-auto'>{r.dailyAverage.toFixed(0)}</span>
                <span>{r.total}</span>
              </div>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  )
}

PlayerLeaderboard.propTypes = propTypes

export default PlayerLeaderboard
