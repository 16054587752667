import axios from 'axios'
import useAuth from '../hooks/useAuth'
import { useContext } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import { AppError } from '../classes/AppError'
import { omitBy, isNil } from 'lodash'

const useDataService = () => {
  const { idToken: token, refreshToken } = useAuth()
  const { setError } = useContext(SharedDataContext)

  const parseAxiosError = error => {
    switch (error.code) {
      case 'ERR_NETWORK':
        return new AppError("We couldn't connect to the server", 'Please try again or contact support')
      case 'ERR_BAD_REQUEST':
        return new AppError('Something went wrong', error.response.data.message)
      default:
        return new AppError('Something went wrong', 'Please try again or contact support')
    }
  }

  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    },
    validateStatus: (status) => status >= 200 && status < 300
  })

  client.interceptors.request.use(async request => {
    if (!request._retry) {
      request.headers.set('Authorization', `Bearer ${token}`)
    }
    return request
  })

  client.interceptors.response.use(response => response, async (error) => {
    const originalRequest = error.config
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const newToken = await refreshToken()
      originalRequest.headers.set('Authorization', `Bearer ${newToken}`)
      return client(originalRequest)
    } else {
      setError(parseAxiosError(error))
    }
    // Promise.reject(error)
  })

  const apiCreateUser = async (data) => {
    const res = await client.post('/_auth/users', data)
    return res?.data
  }

  const apiUpdateUser = async (id, data) => {
    const res = await client.put(`/users/${id}`, data)
    return res?.data
  }

  const apiGetUpdateAuthorization = async (id, data) => {
    const res = await client.post(`/users/${id}/update-authorization`, data)
    return res?.data
  }

  const apiGetMyProfile = async () => {
    const res = await client.get('/users/whoami')
    return res?.data
  }

  const apiUpdatePassword = async (password) => {
    const res = await client.patch('/users/password', { password })
    return res?.data
  }

  const apiGetUser = async (id) => {
    const res = await client.get(`/users/${id}`)
    return res?.data
  }

  const apiCreateOrder = async (data) => {
    const res = await client.post('/orders', data)
    return res?.data
  }

  const apiConfirmEmailChange = async (token) => {
    const res = await client.post(`/users/email-change/confirm?token=${token}`)
    return res?.data
  }

  const apiUpdateOrder = async (id, data) => {
    const res = await client.put(`/orders/${id}`, data)
    return res?.data
  }

  const apiGetTournaments = async (filters, page, pageSize) => {
    const queryString = new URLSearchParams({ ...filters, page, pageSize }).toString()
    const res = await client.get(`/tournaments?${queryString}`)
    return res?.data
  }

  const apiGetTournament = async (id) => {
    const res = await client.get(`/tournaments/${id}`)
    return res?.data
  }

  const apiConfirmOrder = async (orderId, data) => {
    const res = await client.post(`/orders/${orderId}/confirm`, data)
    return res?.data
  }

  const apiGetOrders = async (filters, sort, search, page, pageSize) => {
    if (filters.status.length > 0) {
      filters.status = JSON.stringify(filters.status)
    } else {
      delete filters.status
    }
    if (filters.stringer.length > 0) {
      filters.stringer = JSON.stringify(filters.stringer)
    } else {
      delete filters.stringer
    }
    if (filters.paymentStatus.length > 0) {
      filters.paymentStatus = JSON.stringify(filters.paymentStatus)
    } else {
      delete filters.paymentStatus
    }
    if (filters.orderDate === '') delete filters.orderDate
    // if (search === '') {
    //   search
    // }
    const queryString = new URLSearchParams({ page, pageSize, ...filters, ...sort, search }).toString()
    const res = await client.get(`/orders?${queryString}`)
    return res?.data
  }

  const apiGetPreviousOrder = async (query) => {
    const queryString = new URLSearchParams(query)
    const res = await client.get(`/orders/reload-last?${queryString}`)
    return res?.data
  }

  const apiGetOrder = async (id) => {
    const res = await client.get(`/orders/${id}`)
    return res?.data
  }

  const apiPatchPayment = async (id, data) => {
    const res = await client.patch(`/orders/${id}/payment`, data)
    return res?.data
  }

  const apiPatchPaymentMethod = async (id, data) => {
    const res = await client.patch(`/orders/${id}/payment-method`, data)
    return res?.data
  }

  const apiPatchStatus = async (id, status) => {
    const res = await client.patch(`/orders/${id}/status`, { status })
    return res?.data
  }

  const apiCancelOrder = async (id) => {
    const res = await client.post(`/orders/${id}/cancel`)
    return res?.data
  }

  const apiPatchStringer = async (id, stringer) => {
    const res = await client.patch(`/orders/${id}/stringer`, { stringer })
    return res?.data
  }

  const apiDeleteStringer = async (id) => {
    const res = await client.delete(`/orders/${id}/stringer`)
    return res?.data
  }

  const apiGetStrings = async (filters) => {
    const queryString = new URLSearchParams(filters)
    const res = await client.get(`/strings?${queryString}`)
    return res?.data
  }

  const apiRequestPasswordChange = async (email) => {
    const res = await client.post('/_auth/password-change', { email })
    return res?.data
  }

  const apiUpdatePasswordWithToken = async (code, password) => {
    const res = await client.patch('/_auth/password-change', { code, password })
    return res?.data
  }

  const apiVerifyEmailActionCode = async (code) => {
    const res = await client.get(`/_auth/email-action/verify?token=${code}`)
    return res?.data
  }

  const apiGetUsers = async (query) => {
    query.role = JSON.stringify(query.role)
    const queryString = new URLSearchParams(query)
    const res = await client.get(`/users?${queryString}`)
    return res?.data
  }

  const apiGetPrinters = async () => {
    const res = await client.get('/printers')
    return res?.data
  }

  const apiPutPrinterSettings = async (id, data) => {
    const res = await client.put(`/tournaments/${id}/printer-settings`, data)
    return res?.data
  }

  const apiOverridePrinterSettings = async (id, data) => {
    const res = await client.put(`/tournaments/${id}/printer-settings-override`, data)
    return res?.data
  }

  const apiDeletePrinterSettingsOverride = async (id) => {
    const res = await client.delete(`/tournaments/${id}/printer-settings-override`)
    return res?.data
  }

  const apiPatchAddOns = async (id, data) => {
    const res = await client.patch(`/tournaments/${id}/add-ons`, data)
    return res?.data
  }

  const apiCreatePrintJob = async (orderId, data) => {
    const res = await client.post(`/orders/${orderId}/print`, data)
    return res?.data
  }

  const apiGetReport = async (tournamentId, reportType) => {
    const res = await client.get(`/tournaments/${tournamentId}/report?reportType=${reportType}`)
    return res?.data
  }

  const apiCreateLog = async (data) => {
    const res = await client.post('/logs', data)
    return res?.data
  }

  const apiGetRacquets = async () => {
    const res = await client.get('/racquets')
    return res?.data
  }

  const apiGetFeatures = async () => {
    const res = await client.get('/features')
    return res?.data
  }

  const apiValidatePromoCode = async (code) => {
    const res = await client.get(`/orders/promo-code?code=${code}`)
    return res?.data
  }

  const apiPatchPromoCode = async (orderId, code) => {
    const res = await client.patch(`/orders/${orderId}/promo-code`, { code })
    return res?.data
  }

  const apiGetLocations = async () => {
    const res = await client.get('/locations')
    return res?.data
  }

  const apiCreateTournament = async (data) => {
    const res = await client.post('/tournaments', data)
    return res?.data
  }

  const apiCreateLocation = async (data) => {
    const res = await client.post('/locations', data)
    return res?.data
  }

  const apiUpdateTournamentStaff = async (id, data) => {
    const res = await client.patch(`/tournaments/${id}/staff`, data)
    return res?.data
  }

  const apiPatchTournamentApproval = async (id) => {
    const res = await client.patch(`/tournaments/${id}/approval`)
    return res?.data
  }

  const apiCreateStaff = async (data) => {
    const res = await client.post('/users/staff', data)
    return res?.data
  }

  const apiGetInvoiceData = async (startDate, endDate) => {
    const queryString = new URLSearchParams({ startDate, endDate }).toString()
    const res = await client.get(`/invoices?${queryString}`)
    return res?.data
  }

  const apiEmailInvoice = async (data) => {
    const res = await client.post('/invoices/email', data)
    return res?.data
  }

  const apiGetMetrics = async (eventId, chartId, params) => {
    const queryString = new URLSearchParams(omitBy(params, isNil)).toString()
    const res = await client.get(`/tournaments/${eventId}/metrics/${chartId}?${queryString}`)
    return res?.data
  }

  return {
    apiCreateUser,
    apiGetUpdateAuthorization,
    apiUpdateUser,
    apiGetMyProfile,
    apiGetUser,
    apiConfirmEmailChange,
    apiCreateOrder,
    apiUpdateOrder,
    apiGetTournaments,
    apiGetTournament,
    apiConfirmOrder,
    apiGetOrders,
    apiGetPreviousOrder,
    apiGetOrder,
    apiPatchPayment,
    apiPatchPaymentMethod,
    apiPatchStatus,
    apiCancelOrder,
    apiPatchStringer,
    apiDeleteStringer,
    apiGetStrings,
    apiRequestPasswordChange,
    apiUpdatePassword,
    apiUpdatePasswordWithToken,
    apiVerifyEmailActionCode,
    apiGetUsers,
    apiGetPrinters,
    apiPutPrinterSettings,
    apiOverridePrinterSettings,
    apiDeletePrinterSettingsOverride,
    apiCreatePrintJob,
    apiGetReport,
    apiCreateLog,
    apiGetRacquets,
    apiGetFeatures,
    apiValidatePromoCode,
    apiPatchPromoCode,
    apiGetLocations,
    apiCreateTournament,
    apiCreateLocation,
    apiUpdateTournamentStaff,
    apiPatchTournamentApproval,
    apiCreateStaff,
    apiPatchAddOns,
    apiGetInvoiceData,
    apiEmailInvoice,
    apiGetMetrics
  }
}

export default useDataService
