import { object } from 'prop-types'
import { parseChartsData } from './chart-helpers'
import AnalyticsChart from './AnalyticsChart'

const propTypes = {
  event: object.isRequired
}

const RestringTimesChart = ({ event }) => {
  const CHART_ID = 'restring-time-count'

  const parseData = parseChartsData

  const options = {
    colors: ['#64B97B', '#A95E79', '#F4A56B', '#818FDA'],
    legend: {
      position: 'bottom'
    }
  }

  return (
    <AnalyticsChart
      title='Restring Times'
      chartId={CHART_ID}
      chartType="ColumnChart"
      parseData={parseData}
      options={options}
      event={event}
    />
  )
}

RestringTimesChart.propTypes = propTypes

export default RestringTimesChart
